<template>
  <div id="sidebar">
    <div class="sidebar--label pc">
      <h1 transition="anime">{{ this.$store.state.sidebar_title }}</h1>
    </div>

    <div class="sidebar--nav sp" v-if="$route.name == 'projectdetail'">
      <router-link to="/projects" class="btn-viewall">
        <div class="arrow"><svg viewBox="0 0 5.88 8.97" width="6" height="9"><polygon points="5.23 8.97 0 4.49 5.23 0 5.88 0.76 1.53 4.49 5.88 8.22 5.23 8.97"/></svg></div>
        <div class="text">View All</div>
      </router-link>
    </div>

    <div id="sidebar--scroll--bg"></div>
	</div>
</template>

<script>
  export default {
    name: 'sidebar',
  }
</script>

<style lang="scss" scoped>
  .anime-enter {
      animation: anime-in 1s ease-in;
  }
  .anime-leave {
      animation: anime-out 1s ease-out;
  }
  @keyframes anime-in {
      0% {
          transform: scale(0);
      }
      100% {
          transform: scale(1);
      }
  }
  @keyframes anime-out {
      0% {
          transform: scale(1);
      }
      100% {
          transform: scale(0);
      }
  }
</style>