<template>
  <main id="main" class="top">
    <div class="content__inner__body--left">
      <h1 class="theme--name">
        <span class="fill-group">
          <span class="filled s-3">
            <img src="../assets/images/cicata.svg" alt="CICATA"><span class="subtitle" v-html="$t('subtitle')"></span>
          </span>
          <span class="filler d-1 c-3 s-1"></span>
        </span>
      </h1>
      <p class="theme--title">
        <span class="fill-group">
          <span class="filled s-2" v-html="$t('title')"></span>
          <span class="filler d-1 c-1"></span>
        </span>
      </p>
    
      <span class="fill-group pc">
        <span class="filled s-8">
          <router-link to="/projects" class="btn h-shadow">Our Projects</router-link>
        </span>
        <span class="filler d-1 c-5 s-6"></span>
      </span>
    </div>


    <div class="content__inner__body--right">
      <span class="fill-group">
        <p class="theme--discription filled s-4" v-html="$t('discription')"></p>
        <span class="filler d-1 c-2 s-2"></span>
      </span>
    
      <span class="fill-group pc">
        <span class="filled s-5">
          <div class="todo--title">
            <img src="../assets/images/slash.svg" alt="Business Design">
            <span>Business Design</span>
          </div>
          <div class="todo--text">
            <span>Defining, Strategy, Production, Operation,
              Planning, Design, Consulting</span>
          </div>
        </span>
        <span class="filler d-1 c-3 s-3"></span>
      </span>
    </div>

    <span class="fill-group sp content__button">
      <span class="filled s-8">
        <router-link to="/projects" class="btn h-shadow">Our Projects</router-link>
      </span>
      <span class="filler d-1 c-5 s-6"></span>
    </span>

    <div class="bg_obj">
      <span class="fill-group">
        <span class="filled s-2">
          <img src="../assets/images/logo_w.svg" alt="CICATA">
        </span>
        <span class="filler d-2 c-4"></span>
      </span>
    </div>

    <div class="scroll-down-arrow">
      <img src="../assets/images/v_arrow.svg" alt="">
    </div>
  </main>
</template>

<script>
export default {
  name: 'top',
  data () { return { locale: 'ja' } },
  // watch: {
  //   locale (val) {
  //     this.$i18n.locale = val
  //   }
  // },
  created(){
    // this.$store.commit('setLoading', true);
    this.$store.commit('setSubFooter', false);
    this.$store.commit('setSidebarTitle', 'CICATA, inc.');
  },
  mounted(){
    document.title = "CICATA, Inc.（シカタ株式会社）- ブランディングデザインファーム";
    // this.$store.commit('setLoading', false);
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/scss/top";
</style>

<i18n>
{
  "en": {
    "subtitle": " ",
    "title": "Maximizeyour brand value",
    "discription": "We are a branding design firm specializing in business and design. We offer business strategy, product planning, development, visual design, operation and sales support. In order to maximize the value of the brand that is sleeping in the customer, we continue to support and communicate with our customers."
  },
  "ja": {
    "subtitle": "のしかた",
    "title": "お客様のブランド価値を<br>最大限に",
    "discription": "私たちは、ビジネス・デザイン領域を得意としたブランディングデザインファームです。お客様の中に眠っているブランド価値を最大限に引き出す為に、コミュニケーションを重ね、経営戦略や商品の企画・開発、ビジュアルデザイン、運用、営業支援に至るまで、常に寄り添いながら、それぞれに合ったご提案と長期的なサポートをさせていただきます。"
  }
}
</i18n>