<template>
  <main id="main" class="about">
    <!-- Main Visual -->
    <section class="section__visual">
      <div class="content">
        <div class="content__inner" v-inview:once="showMainVisual">
          <div class="section__visual--main js-anim-image">
            <img src="../assets/images/about_main.jpg" width="906" alt="">
          </div>
        </div>
      </div>
    </section>

    <!-- About -->
    <section id="about" class="section">
      <div class="content">
        <div class="content__inner__body" v-inview:once="showAbout">
          <h2 class="section--title js-anim-title">Philosophy</h2>
          <div class="section--theme js-anim-theme" v-html="$t('about-theme')"></div>
          <div class="section--discription js-anim-text">
            <p v-html="$t('about-discription')"></p>
          </div>
          <div class="section--subimage pc js-anim-image">
            <img src="../assets/images/about_side.jpg" width="400" alt="">
          </div>
        </div>
      </div>
    </section>

    <!-- Service -->
    <section id="services" class="section lightgray">
      <div class="content">
        <div class="content__inner__body" v-inview:once="showService">
          <h2 class="section--title js-anim-title">Services</h2>
          <div class="section--theme js-anim-theme" v-html="$t('service-theme')"></div>
          <div class="section--discription--col-2">
            <div class="section--discription--left js-anim-text">
              <p v-html="$t('service-discription')"></p>
            </div>
            <div class="section--discription--right js-anim-button">
              <!-- <div class="section--button pc">
                <router-link to="/projects" class="btn darkgray h-shadow">Our Projects</router-link>
              </div> -->
            </div>
          </div>

          <div class="section--flow">
            <ul class="section--flow--list js-anim-flow">
              <li class="section--flow--list--item js-anim-flowlist01">
                <dl>
                  <dt class="js-anim-service-title01 af-allow">{{$t('service-01-title')}}<span class="line01"></span></dt>
                  <dd>
                    <ul>
                      <li class="js-anim-service-item" v-html="$t('service-01-01')"></li>
                      <li class="js-anim-service-item" v-html="$t('service-01-02')"></li>
                      <li class="js-anim-service-item" v-html="$t('service-01-03')"></li>
                      <li class="js-anim-service-item" v-html="$t('service-01-04')"></li>
                      <li class="js-anim-service-item" v-html="$t('service-01-05')"></li>
                    </ul>
                  </dd>
                </dl></li>
              <li class="section--flow--list--item js-anim-flowlist02">
                <dl>
                  <dt class="js-anim-service-title02 af-allow">{{$t('service-02-title')}}<span class="line02"></span></dt>
                  <dd>
                    <ul>
                      <li class="js-anim-service-item" v-html="$t('service-02-01')"></li>
                      <li class="js-anim-service-item" v-html="$t('service-02-02')"></li>
                      <li class="js-anim-service-item" v-html="$t('service-02-03')"></li>
                      <li class="js-anim-service-item" v-html="$t('service-02-04')"></li>
                      <li class="js-anim-service-item" v-html="$t('service-02-05')"></li>
                    </ul>
                  </dd>
                </dl></li>
              <li class="section--flow--list--item js-anim-flowlist03">
                <dl>
                  <dt class="js-anim-service-title03 af-allow">{{$t('service-03-title')}}<span class="line03"></span></dt>
                  <dd>
                    <ul>
                      <li class="js-anim-service-item" v-html="$t('service-03-01')"></li>
                      <li class="js-anim-service-item" v-html="$t('service-03-02')"></li>
                      <li class="js-anim-service-item" v-html="$t('service-03-03')"></li>
                      <li class="js-anim-service-item" v-html="$t('service-03-04')"></li>
                      <li class="js-anim-service-item" v-html="$t('service-03-05')"></li>
                    </ul>
                  </dd>
                </dl></li>
              <li class="section--flow--list--item js-anim-flowlist04">
                <dl>
                  <dt class="js-anim-service-title04" v-html="$t('service-04-title')"></dt>
                  <dd>
                    <ul>
                      <li class="js-anim-service-item" v-html="$t('service-04-01')"></li>
                      <li class="js-anim-service-item" v-html="$t('service-04-02')"></li>
                      <li class="js-anim-service-item" v-html="$t('service-04-03')"></li>
                      <li class="js-anim-service-item" v-html="$t('service-04-04')"></li>
                      <li class="js-anim-service-item" v-html="$t('service-04-05')"></li>
                    </ul>
                  </dd>
                </dl></li>
            </ul>
          </div>

          <div class="section--button js-anim-button_bottom">
            <router-link to="/projects" class="btn darkgray">Our Projects</router-link>
          </div>
        </div>
      </div>
    </section>

    <!-- Members -->
    <section id="member" class="section-no-bottom">
      <div class="content">
        <div class="content__inner__body">
          <h2 class="section--title pd">Members</h2>
        </div>
      </div>
      <div class="content fullwidth">
        <div class="content__inner__body">
          <div class="section--member">
            <div class="section--member--list">

              <div class="section--member--list--item">
                <a href="#" class="tilter tilter--2">
                  <figure class="item--figure" >
                    <img class="item--image" src="../assets/images/kitamoto.jpg" alt="">
                    <figcaption class="item--meta">
                      <div class="member--position" v-html="$t('member-position-01')"></div>
                      <div class="member--name" v-html="$t('member-name-01')"></div>
                    </figcaption>
                  </figure>
                </a>
              </div>

              <div class="section--member--list--item">
                <a href="#" class="tilter tilter--2">
                  <figure class="item--figure" >
                    <img class="item--image" src="../assets/images/nagata.jpg" alt="">
                    <figcaption class="item--meta">
                      <div class="member--position" v-html="$t('member-position-02')"></div>
                      <div class="member--name" v-html="$t('member-name-02')"></div>
                    </figcaption>
                  </figure>
                </a>
              </div>

              <div class="section--member--list--item">
                <a href="#" class="tilter tilter--2">
                  <figure class="item--figure">
                    <img class="item--image" src="../assets/images/kinoshita.jpg" alt="">
                    <figcaption class="item--meta">
                      <div class="member--position" v-html="$t('member-position-03')"></div>
                      <div class="member--name" v-html="$t('member-name-03')"></div>
                    </figcaption>
                  </figure>
                </a>
              </div>

              <div class="section--member--list--item">
                <a href="#" class="tilter tilter--2">
                  <figure class="item--figure">
                    <img class="item--image" src="../assets/images/sheila.jpg" alt="">
                    <figcaption class="item--meta">
                      <div class="member--position" v-html="$t('member-position-04')"></div>
                      <div class="member--name" v-html="$t('member-name-04')"></div>
                    </figcaption>
                  </figure>
                </a>
              </div>

              <div class="section--member--list--item">
                <a href="#" class="tilter tilter--2">
                  <figure class="item--figure">
                    <img class="item--image" src="../assets/images/fukuda.jpg" alt="">
                    <figcaption class="item--meta">
                      <div class="member--position" v-html="$t('member-position-05')"></div>
                      <div class="member--name" v-html="$t('member-name-05')"></div>
                    </figcaption>
                  </figure>
                </a>
              </div>

              <div class="section--member--list--item">
                <a href="#" class="tilter tilter--2">
                  <figure class="item--figure">
                    <img class="item--image" src="../assets/images/matsuda.jpg" alt="">
                    <figcaption class="item--meta">
                      <div class="member--position" v-html="$t('member-position-06')"></div>
                      <div class="member--name" v-html="$t('member-name-06')"></div>
                    </figcaption>
                  </figure>
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Clients -->
    <section class="section">
      <div class="content">
        <div class="content__inner__body">
            <h2 class="section--title">Clients</h2>
            <ul class="section--clients--list">
              <li><img src="../assets/images/logo_001.png" alt=""></li>
              <li><img src="../assets/images/logo_002.png" alt=""></li>
              <li><img src="../assets/images/logo_003.png" alt=""></li>
              <li><img src="../assets/images/logo_004.png" alt=""></li>
              <li><img src="../assets/images/logo_005.png" alt=""></li>
              <li><img src="../assets/images/logo_006.png" alt=""></li>
              <li><img src="../assets/images/logo_007.png" alt=""></li>
              <li><img src="../assets/images/logo_008.png" alt=""></li>
              <li><img src="../assets/images/logo_009.png" alt=""></li>
            </ul>
        </div>
        <div class="section--button js-anim-button_bottom">
          <router-link to="/projects" class="btn darkgray">Our Projects</router-link>
        </div>
      </div><!-- /contentinner -->
    </section>

    <!-- <transition>
      <subfooter></subfooter>
    </transition> -->
  </main>
</template>

<script>
// import TweenMax from 'gsap'
// import TimelineMax from 'gsap'
// import Power3 from 'gsap'
// import Power4 from 'gsap'
// import SplitText from "../gsap-bonus/SplitText"

export default {
  name: 'about',
  data () { return { locale: 'ja' } },
  watch: {
    locale (val) {
      this.$i18n.locale = val
    }
  },
  created(){
    this.$store.commit('setLoading', true)
    this.$store.commit('setSidebarTitle', 'About');
    this.$store.commit('setSubFooter', false);
  },
  mounted(){
    document.title = "About us | CICATA, Inc.（シカタ株式会社）- ブランディングデザインファーム";
    this.$store.commit('setLoading', false);
    this.$store.commit('setSubFooter', true);
    
    var scroll_area_height = window.innerHeight - document.getElementById('subfooter').clientHeight;
    this.$store.commit('setContentHeight', document.getElementById('main').clientHeight - scroll_area_height)
  },
  methods: {
    showImage(el){
      const tl = new TimelineMax()
      const target = el.querySelector('.js-anim-image')
      const span = document.createElement('span')
      const img = target.querySelector('img')
      target.appendChild(span)
      tl
        .set(span, { transformOrigin: 'left top', scaleX: 0 })
        .to(span, 0.8, { scaleX: 1, ease: Power4.easeInOut })
        .to(img, 0, { opacity: 1 })
        .to(span, 0.8, { transformOrigin: 'right top', scaleX: 0, ease: Power4.easeInOut });
    },
    showMainVisual(el){
      el.enter = (el) =>{
        this.showImage(el)
      }
    },
    showAbout (el) {
      el.enter = (el) =>{
        let tl = new TimelineMax()
        let titleObj = el.getElementsByClassName('js-anim-title')
        let themeObj = el.getElementsByClassName('js-anim-theme')
        let txtObj = el.getElementsByClassName('js-anim-text')
        //let imageObj = el.getElementsByClassName('js-anim-image')

        var mySplitText = new SplitText(titleObj, {type: 'words, chars'})
        var chars = mySplitText.chars
        tl
          .staggerFrom(chars, 1.0, {opacity:0, y:60, ease: Power3.easeInOut}, 0.01)
          .from(themeObj, 1.0, {opacity:0, y:20, ease: Power3.easeInOut}, '-=0.6')
          .from(txtObj, 1.0, {opacity:0, y:20, ease: Power3.easeInOut}, '-=0.6')

        this.showImage(el)
      }
    },
    showService (el) {
      el.enter = (el) =>{
        let tl = new TimelineMax()
        let titleObj = el.getElementsByClassName('js-anim-title')
        let themeObj = el.getElementsByClassName('js-anim-theme')
        let buttonObj = el.getElementsByClassName('js-anim-button')
        let txtObj = el.getElementsByClassName('js-anim-text')

        let flowObj = el.getElementsByClassName('js-anim-flow')
        // let flowListObj = el.getElementsByClassName('js-anim-flowlist')

        let button_bottomObj = el.getElementsByClassName('js-anim-button_bottom')

        var mySplitText = new SplitText(titleObj, {type: 'words, chars'})
        var chars = mySplitText.chars

        tl
          .staggerFrom(chars, 1.0, {opacity:0, y:60, ease: Power3.easeInOut}, 0.01)
          .from(themeObj, 1.0, {opacity:0, y:20, ease: Power3.easeOut}, '-=0.6')
          .from(txtObj, 1.0, {opacity:0, y:20, ease: Power3.easeOut}, '-=0.6')
          .from(buttonObj, 1.0, {opacity:0, y:20, ease: Power3.easeOut}, '-=1.4')
        // tl.from(flowObj, 0.8, {opacity:0, y:40, ease: Power3.easeInOut}, '-=0.6')

        tl.from(flowObj, 1.0, {opacity:0, y:20, ease: Power3.easeOut}, '-=0.4')
        tl.from(button_bottomObj, 1.0, {opacity:0, y:20, ease: Power3.easeInOut}, '-=0.4')
      }
    },
    showTitle (el) {
      el.enter = (el) =>{
        var mySplitText = new SplitText(el, {type: 'words, chars'})
        var chars = mySplitText.chars      
        TweenMax.staggerFrom(chars, 0.8, {opacity:0, y:80, ease: Power4.easeOut, delay: 0.4}, 0.01)
      }
    },
    showText (el) {
      el.enter = (el) =>{
        var mySplitText = new SplitText(el, {type: 'words'})
        var words = mySplitText.words      
        TweenMax.staggerFrom(words, 0.8, {opacity:0, y:80, ease: Power4.easeOut, delay: 0.4})
      }
    },
    showVisual (el) {
      el.enter = (el) =>{ 
        TweenMax.from(el, 0.8, {opacity:0, y:80, ease: Power4.easeInOut, delay: 0.4})
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .td2{
    transition-delay: 0.2s;
  }
  .td3{
    transition-delay: 0.3s;
  }
</style>

<i18n>
{
  "ja": {
    "about-theme": "言語や文化の垣根を超える「共感」を<br>夢や希望を語ることのできる「豊かさ」を",
    "about-discription": "モノや情報があふれている世の中、物事の本質を見極めることができれば、言語や文化の垣根を超えた、豊かな未来を作ることができると考えています。私たちは常に、本質的な価値とは何かに向き合い、問題を解決していきます。",
    "service-theme": "ビジネス・デザイン",
    "service-discription":"ブランディングとは、ビジネス全体を一気通貫してデザインすることだと考えます。私たちは「なぜこの事業を行っているのか？」「なぜこの商品が必要なのか？」という、「なぜ？」を常に問いながら、ブランド価値を呼び起こしてまいります。",

    "service-01-title": "定 義",
    "service-01-01": "ヒアリング",
    "service-01-02": "市場調査",
    "service-01-03": "現状分析",
    "service-01-04": "競合分析",
    "service-01-05": "論点整理",

    "service-02-title": "戦 略",
    "service-02-01": "仮説立案",
    "service-02-02": "ビジネス設計",
    "service-02-03": "コンセプト策定",
    "service-02-04": "市場精査",
    "service-02-05": "商品／サービス精査",

    "service-03-title": "制 作",
    "service-03-01": "ネーミング／ロゴ",
    "service-03-02": "グラフィックデザイン",
    "service-03-03": "ウェブデザイン",
    "service-03-04": "商品開発",
    "service-03-05": "スペースデザイン",

    "service-04-title": "運 用",
    "service-04-01": "販売促進",
    "service-04-02": "営業支援",
    "service-04-03": "トレーニング",
    "service-04-04": "効果検証",
    "service-04-05": "PR／広告",

    "member-position-01": "Business Designer<br><span>代表取締役</span>",
    "member-name-01": "北本 浩一郎",

    "member-position-02": "Business Designer<br><span>執行役員</span>",
    "member-name-02": "永田 大介",

    "member-position-03": "Graphic Designer",
    "member-name-03": "木下 塁",

    "member-position-04": "Graphic Designer",
    "member-name-04": "秋山 シェイラ",


    "member-position-05": "Web Director",
    "member-name-05": "福田 和彦",

    "member-position-06": "Project Manager",
    "member-name-06": "松田 里美"
  },
  "en": {
    "about-theme": "sympathy -beyond the boundaries of language and culture<br>abundance -where one can talk dreams and hope",
    "about-discription": "If we can see the essence of things in a world crowded with things and information, we believe we can create a rich abundance beyond the boundaries of languages and cultures. We will always face something with essential value and solve the problem.",
    "service-theme": "Business Design",
    "service-discription":"We believe that branding is designing whole business from the beginning to the end. We are constantly asking ‘Why?’, ‘Why are we doing this project?’ ‘Why is this product necessary?’ That is how we evoke brand values.",

    "service-01-title": "Defining",
    "service-01-01": "Hearing",
    "service-01-02": "Market research",
    "service-01-03": "Current status analysis",
    "service-01-04": "Competitive analysis",
    "service-01-05": "Briefing",

    "service-02-title": "Strategy",
    "service-02-01": "Hypothesis planning",
    "service-02-02": "Business design",
    "service-02-03": "Concept formulation",
    "service-02-04": "Market Review",
    "service-02-05": "Product / service scrutiny",

    "service-03-title": "Production",
    "service-03-01": "Naming/Logo",
    "service-03-02": "Graphic design",
    "service-03-03": "Web design",
    "service-03-04": "Product development",
    "service-03-05": "Spatial design",

    "service-04-title": "Operation",
    "service-04-01": "Sales promotion",
    "service-04-02": "Business support",
    "service-04-03": "Training",
    "service-04-04": "Effectiveness analysis",
    "service-04-05": "Advertisement",

    "member-position-01": "Business Designer<br><span>CEO</span>",
    "member-name-01": "Koichiro Kitamoto",

    "member-position-02": "Business Designer<br><span>Executive Officer</span>",
    "member-name-02": "Daisuke Nagata",

    "member-position-03": "Graphic Designer",
    "member-name-03": "Rui Kinoshita",

    "member-position-04": "Graphic Designer",
    "member-name-04": "Sheila Akiyama",

    "member-position-05": "Web Director",
    "member-name-05": "Kazuhiko Fukuda",

    "member-position-06": "Project Manager",
    "member-name-06": "Satomi Matsuda"
  }
}
</i18n>