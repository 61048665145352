<template>
  <div id="subfooter" class="section black section--footer">
    <div class="content">
      <div class="content__inner__body">
        <h2 class="section--title black">Contact</h2>
        <dl class="section--contact--list">
          <dt v-html="$t('heading-01')"></dt>
          <dd v-html="$t('data-01')"></dd>

          <dt v-html="$t('heading-02')"></dt>
          <dd>
            <span v-html="$t('data-02')"></span>
            <div class="section--contact--map">
              <a href="https://goo.gl/maps/M1ELfyYLLjX8KaXHA" target="_blank" class="section--contact--map--link">Google Map</a>
            </div>
          </dd>

          <dt v-html="$t('heading-03')"></dt>
          <dd><a href="mailto:info@cicata.jp" class="section--contact--mail">info@cicata.jp</a></dd>
        </dl>
      </div>
    </div>
    <div class="content">
      <div class="content__inner__body">
        <div class="copyright">&copy;︎ CICATA, INC.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'subfooter',
  data () { return { locale: 'ja' } },
  watch: {
    locale (val) {
      this.$i18n.locale = val
    }
  },
}
</script>

<i18n>
{
  "ja":{
    "heading-01":"社名",
    "data-01":"シカタ株式会社",
    "heading-02":"所在地",
    "data-02":"〒107-0062<br>東京都港区南青山7-13-28 <br class='sp'>CAPRICE 201",
    "heading-03":"お問合せ"
  },
  "en":{
    "heading-01":"Company",
    "data-01":"CICATA, Inc",
    "heading-02":"Location",
    "data-02":"201, CAPRICE, 7-13-28, Minami Aoyama, Minato-ku, Tokyo,<br>107-0062, Japan",
    "heading-03":"Contact"
  }
}
</i18n>
