import Vue from 'vue'
import VueRouter from 'vue-router'

import Top from '@/views/Top'
import About from '@/views/About'
import List from '@/views/ProjectsList'
import Details from '@/views/Details'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'top',
    component: Top,
    // meta: {
    //   title: 'CICATA, Inc.（シカタ株式会社）- ブランディングデザインファーム'
    // }
  },
  {
    path: '/about',
    name: 'about',
    component: About,
    // meta: {
    //   title: 'About Us | CICATA, Inc.（シカタ株式会社）- ブランディングデザインファーム'
    // }
  },
  {
    path: '/projects',
    name: 'Our Projects',
    component: List,
    // meta: {
    //   title: 'Our Projects | CICATA, Inc.（シカタ株式会社）- ブランディングデザインファーム'
    // }
  },
  {
    path: '/projects/:projectname',
    name: 'projectdetail',
    component: Details,
    // meta: {
    //   title: ''
    // }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',

  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      let position = {}
      if (to.hash) {
        position = { selector: to.hash }
      } else {
        position = { x: 0, y: 0 }
      }
      // return position
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(position)
        }, 500)
      })
    }
  }
});

// router.afterEach((to, from) => {
//   if (to.meta && to.meta.title) {
//     document.title = to.meta.title
//   }
// })
export default router