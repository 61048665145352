<template>
  <transition name="anime-loading" appear mode="out-in">
    <div class="loading" v-show="$store.state.loading">
      <div class="loading-inner">
        Loading ...
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'loading'
}
</script>
<style lang="scss" scoped>
  // loading
  .loading{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999999;
    background-color: #e4e5db;
    transition: all .3s;
    width: 100%;
    // height: 100%;
    height: 100vh;
    .loading-inner{
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #424242;
    }
  }
  .anime-loading-enter-active {
    transition: all 0.3s ease-out;
    transition-delay: 0.3s;
  }
  .anime-loading-leave-active {
    transition: all 0.3s ease-in;
    transition-delay: 0.3s;
  }
  .anime-loading-enter {
    opacity: 0;
  }
  .anime-loading-enter-to{
    opacity: 1;
  }
  .anime-loading-leave {
    opacity: 1;
  }
  .anime-loading-leave-to {
    opacity: 0;
  }
</style>