<template>
  <main id="main" class="projects">
    <section class="section__project" key="project-list">
      <div class="content">
        <div class="content__inner">
          <div class="section__project--list">

            <transition-group name="flip" tag="div" @before-enter="beforeEnter" @after-enter="afterEnter">
              <article class="section__project--list--item" v-for="(item, index) in res[this.$store.state.lang]" :key="index">
                <router-link :to="{name: 'projectdetail', params:{projectname:item.slug}}">
                  <div class="project--number"><div class="number">{{ item.num | zeroPadding() }}</div></div>
                  <div class="project--name">
                    <h2 class="en" v-html="item.project_title"></h2><span class="jp" v-html="$t(item.name)"></span>
                    <div class="project--btn-wrapper">
                      <div class="btn viewmore">View More</div>
                    </div>
                  </div>
                  <div class="project--thumb"><figure class="thumb"><img :src="item.thumbnail" width="460" :alt="$t(item.name)" /></figure></div>
                </router-link>
              </article>
            </transition-group>

            <infinite-loading
              spinner="waveDots"
              @infinite="infiniteHandler"
              :distance="100">
              <div slot="no-more" />
            </infinite-loading>

          </div>
        </div>
      </div>
    </section>
    <!-- <transition>
      <subfooter v-show="show"></subfooter>
    </transition> -->
  </main>
</template>

<script>
  import axios from 'axios';
  export default {
    name: 'projectslist',
    data () {
      return {
        list: [],
        res: {},
        page: 1,
        maxpage: 1,
        api: '',
      }
    },
    watch:{
      '$route': function(){
        this.$store.commit('setSubFooter', false);
      },
    },
    created() {
      this.$store.commit('setLoading', true);
      this.$store.commit('setSubFooter', false);
      this.$store.commit('setSidebarTitle', 'Our Projects');
    },
    methods: {
      infiniteHandler($state) {
        this.api = "https://cicata.jp/api/project/page/" + this.page + "/";
        axios.get(this.api)
          .then(response => {
          if (response.data.article.length) {
            this.$store.commit('projectsLoad', false)
            this.maxpage = response.data.meta.max_num_pages;
            this.page++;
            this.list.push(...response.data.article);

            let result = {}
            result['ja'] = {}
            result['en'] = {}

            let count = 0;
            Object.keys( this.list ).forEach( function(val){
              result['ja'][count] = {};
              result['en'][count] = {};
              
              result['ja'][count]['num'] = this[val]['num'];
              result['en'][count]['num'] = this[val]['num'];

              result['ja'][count]['project_title'] = this[val]['project_title'];
              result['en'][count]['project_title'] = this[val]['project_title'];

              result['ja'][count]['slug'] = this[val]['slug'];
              result['en'][count]['slug'] = this[val]['slug'];

              result['ja'][count]['thumbnail'] = this[val]['thumbnail'];
              result['en'][count]['thumbnail'] = this[val]['thumbnail'];

              result['ja'][count]['name'] = this[val]['name_ja'];
              result['en'][count]['name'] = this[val]['name_en'];
              count++;
            }, this.list );
          
            this.$i18n.setLocaleMessage('ja', result['ja']);
            this.$i18n.setLocaleMessage('en', result['en']);
            this.res = result;

            $state.loaded();
          } else {
            $state.complete();
          }
          this.$store.commit('setLoading', false)
          document.title = "Our Projects | CICATA, Inc.（シカタ株式会社）- ブランディングデザインファーム";
        }).catch( error => {
          $state.complete();
          this.$store.commit('setLoading', false);
        });
      },
      beforeEnter(el){
        el.style.transitionDelay = 100 * parseInt(el.dataset.index, 30) + 'ms'
        console.log('beforeEnter');
      },
      afterEnter(el) {
        el.style.transitionDelay = ''

        var scroll_area_height = window.innerHeight - document.getElementById('subfooter').clientHeight;
        this.$store.commit('setContentHeight', document.getElementById('main').clientHeight - scroll_area_height)
        console.log('afterEnter');
        this.$store.commit('setSubFooter', true);
      }
    }
  }
</script>

<style lang="scss" scoped>
  #main{
    min-height: 100vh;
  }
  .v-lazy-image {
    opacity: 0;
    transition: opacity 0.5s;
  }
  .v-lazy-image-loaded {
    opacity: 1;
  }
 
  .flip {
  // 要素が移動するときのアニメーション設定（基本的にはtransitionにtransformを設定していればいい）
  // &-move {
  //   transition: transform 0.3s;
  // }

  // 要素が入るときのアニメーション
  &-enter {
    // アニメーションの初期設定（初期値とtransitionを設定する）
    &-active {
      opacity: 0;
      transform: translate3d(0, 50px, 0);
      transition: opacity 0.5s cubic-bezier(.05,.37,.38,1), transform 0.5s cubic-bezier(.05,.37,.38,1), delay 0.3s;
    }
    // アニメーション開始（目標のプロパティ値を設定する）
    &-to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  // 要素が消える時のアニメーション
  &-leave {
    // アニメーションの初期設定
    &-active {
      // 要素が消える場合はabsoluteにする（重要！）
      position: absolute;
      transition: opacity 0.5s cubic-bezier(.05,.37,.38,1), transform 0.5s cubic-bezier(.05,.37,.38,1), delay 0.3s;
    }
    // アニメーション開始
    &-to {
      opacity: 0;
      transform: translate3d(0, 50px, 0);
    }
  }
}
</style>