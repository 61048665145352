// Vue.config.productionTip = false
/* eslint-disable */
import 'es6-promise/auto'
import 'babel-polyfill'
import Vue from 'vue'
import Vuex from 'vuex'

import VueObserveVisibility from 'vue-observe-visibility'
// import VueI18n from 'vue-i18n'
import VueAnalytics from 'vue-analytics'
import App from './App'
import router from './router'
import store from './store'

import Sidebar from '@/components/Sidebar'
import SubFooter from '@/components/SubFooter'
import Loading from '@/components/Loading'

import Inview from 'vueinview'
import VueScrollTo from 'vue-scrollto'

import TweenMax from 'gsap'
import TimelineMax from 'gsap'
import SplitText from "./gsap-bonus/SplitText"
import i18n from './i18n'

import InfiniteLoading from 'vue-infinite-loading';
import { VLazyImagePlugin } from 'v-lazy-image';

Vue.use(InfiniteLoading);
Vue.use(VLazyImagePlugin);

// import _ from 'lodash';
// Vue.use(TweenMax)
// Vue.use(TimelineMax)
// Vue.use(SplitText)

Vue.use(VueScrollTo)
Vue.use(Inview)
// Inview.offset(400)

Vue.use(VueAnalytics, {
  id: 'UA-47902882-1',
  router
})

Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(VueObserveVisibility)

Vue.component('sidebar', {
  template: '<Sidebar/>',
  components: { Sidebar }
})

Vue.component('subfooter', {
  template: '<SubFooter/>',
  components: { SubFooter }
})

Vue.component('loading', {
  template: '<Loading/>',
  components: { Loading }
})

Vue.filter('zeroPadding', function(value, num){
  var num = typeof num !== 'undefined' ? num : 2;
    return value.toString().padStart(num,"0");
});

/* eslint-disable */
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

var wheelDistance = function(evt){
  if (!evt) evt = event;
  var w=evt.wheelDelta, d=evt.detail;
  if (d){
    if (w) return w/d/40*d>0?1:-1; // Opera
    else return -d/3;              // Firefox;         TODO: do not /3 for OS X
  } else return w/120;             // IE/Safari/Chrome TODO: /3 for Chrome OS X
};

var doScroll = function (e) {
  if (router.currentRoute.name == "top" && wheelDistance(e) < -0.4) {
    router.push({ path: "about" });
  }
}

document.addEventListener('load', function(){
  setSidebarHeight();
},false);

document.addEventListener("mousewheel", doScroll, false);
document.addEventListener('touchmove', event => {
  console.log(event);
  if (event.touches.length > 1) {
    event.preventDefault();
  }
  if (router.currentRoute.name == "top") {
    router.push({ path: "about" });
  }
}, true);

var timer = null;
var timeoutId;
document.addEventListener("scroll", function(){
  if(timer === null){
    timer = setTimeout(function(){
      clearTimeout(timer)
      // store.commit('setScrollY', scroll )
      // var h = Math.round(scroll / store.state.contentHeight * 100,2);
      // document.getElementById('sidebar--scroll--bg').style.height = h + '%';
      timer = null
    }, 500);
  }

  clearTimeout( timeoutId );
    timeoutId = setTimeout( function () {
      setSidebarHeight();
    }, 10 ) ;
}, false);

var setSidebarHeight = function(){
  var scroll_area_height = window.innerHeight - document.getElementById('subfooter').clientHeight;
  if (router.currentRoute.name == "projectdetail") {
    scroll_area_height = window.innerHeight - document.getElementById('subfooter').clientHeight - document.getElementById('detail--spec--nav').clientHeight;
  }
  var contentHeight = document.getElementById('main').clientHeight - scroll_area_height;
  var scroll = (window.pageYOffset !== undefined) ? window.pageYOffset : document.documentElement.scrollTop;
  var h = Math.ceil(scroll / contentHeight * 1000)/10;
  if (router.currentRoute.name == "projectdetail") {
    if(h > 95){
      document.querySelector('.sidebar--label').style.opacity = 0;
    }else{
      document.querySelector('.sidebar--label').style.opacity = 1;
    }
  }
  if(h <= 100){
    document.getElementById('sidebar--scroll--bg').style.height = h + '%';
  }
  return false;
}