<template>
  <div id="app">
    <div id="container">
      <header id="header">
        <div class="header__logo">
          <router-link to="/" exact>
            <img src="./assets/images/logo.svg" alt="CICATA">
          </router-link>
        </div>

        <div class="nav--item pc">
          <router-link to="/about" class="nav-about" exact>About</router-link>
          <router-link to="/projects" class="nav-about" exact>Our Projects</router-link>
        </div>

        <nav class="header__nav pc">
          <div class="header__lang">
            <span>
              <a href="#"
                v-bind:class="{ isActive: this.$store.state.isJa }"
                v-on:click.prevent="handleClick_changeLanguage('ja')">JA</a></span>
            <span class="dash">-</span>
            <span>
              <a href="#"
                v-bind:class="{ isActive: this.$store.state.isEn }"
                v-on:click.prevent="handleClick_changeLanguage('en')">EN</a></span>
          </div>

          <div class="header__sns">
            <ul>
              <li><a href="https://www.facebook.com/cicatainfo/" title="facebook" target="_blank"><i class="icon-facebook"></i></a></li>
              <li><a href="https://www.instagram.com/cicata_inc/" title="instagram" target="_blank"><i class="icon-instagram"></i></a></li>
              <li><a href="https://www.behance.net/CICATAInc" title="behance" target="_blank"><i class="icon-behance"></i></a></li>
            </ul>
          </div>
        </nav>

        <div class="header__nav__hum sp">
          <div class="menu-trigger" v-bind:class='{active:show}' v-on:click='toggleMenu()'>
            <span></span>
            <span></span>
          </div>

          <!-- <div class="nav__hum--wrapper"> -->
            <nav class="nav__hum--bg" v-bind:class='{active:show}'>
              <div class="hum__lang">
                <div class="header__lang">
                  <span>
                    <a href="#"
                      v-bind:class="{ isActive: this.$store.state.isJa }"
                      v-on:click.prevent="handleClick_changeLanguage('ja')">JA</a></span>
                  <span class="dash">-</span>
                  <span>
                    <a href="#"
                      v-bind:class="{ isActive: this.$store.state.isEn }"
                      v-on:click.prevent="handleClick_changeLanguage('en')">EN</a></span>
                </div>
              </div>
              <ul class="hum__list">
                <li class="hum__list--item">
                  <router-link v-on:click.native='toggleMenu()' to="/" exact>Home</router-link>
                </li>
                <li class="hum__list--item">
                  <router-link v-on:click.native='toggleMenu()' to="/about" v-scroll-to="'#main'" exact>About</router-link>
                </li>
                <li class="hum__list--item">
                  <router-link v-on:click.native='toggleMenu()' to="/about#services" v-scroll-to="'#services'" exact>Services</router-link>
                </li>
                <li class="hum__list--item">
                  <router-link v-on:click.native='toggleMenu()' to="/projects" exact>Our Projects</router-link>
                </li>
                <li class="hum__list--item">
                  <router-link v-on:click.native='toggleMenu()' to="/about#member" v-scroll-to="'#member'" exact>Member</router-link>
                </li>
                <li class="hum__list--item">
                  <router-link v-on:click.native='toggleMenu()' to="/about#subfooter" v-scroll-to="'#subfooter'" exact>Contact</router-link>
                </li>
              </ul>
              <div class="hum__sns">
                <div class="header__sns">
                  <ul>
                    <li><a href="https://www.facebook.com/cicatainfo/" title="facebook" target="_blank"><i class="icon-facebook"></i></a></li>
                    <li><a href="https://www.instagram.com/cicata_inc/" title="instagram" target="_blank"><i class="icon-instagram"></i></a></li>
                    <li><a href="https://www.behance.net/CICATAInc" title="behance" target="_blank"><i class="icon-behance"></i></a></li>
                  </ul>
                </div>
              </div>
            </nav>
          <!-- </div> -->
        </div>
      </header>
      <div class="wrapper">
        <!-- <transition name="anime-loading" appear mode="out-in">
          <div class="loading" v-show="$store.state.loading">
            <div class="loading-inner">
              Loading ...
            </div>
          </div>
        </transition> -->
        <div class="detail--nav pc" v-if="$route.name == 'projectdetail'" appear mode="out-in">
          <router-link to="/projects" class="btn-viewall">
            <div class="arrow"><svg viewBox="0 0 5.88 8.97" width="6" height="9"><polygon points="5.23 8.97 0 4.49 5.23 0 5.88 0.76 1.53 4.49 5.88 8.22 5.23 8.97"/></svg></div>
            <div class="text">View All</div>
          </router-link>
        </div>

        <transition name="anime-sidebar" appear mode="out-in">
          <sidebar />
        </transition>
        
        <transition name="anime" appear mode="out-in">
          <router-view />
        </transition>
      </div>

      <transition>
        <subfooter v-show="this.$store.state.subfooter_show"></subfooter>
      </transition>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  export default {
    name: 'app',
    data: function () {
      return {
        lang: 'ja',
        isActive: false,
        show: false
      }
    },
    watch: {
      // '$route': function (to, from) {
      //   if (to.path !== from.path) {
      //     console.log('from : ' + from.path);
      //     console.log('to : ' + to.path);
      //   }
      // }
      // '$route': 'hiddenSubFooter'
    },
    mounted: function () {
      window.addEventListener("load", this.handleResize, false)
      window.addEventListener('resize', this.handleResize, false)
    },
    // updated: function(){
    //   window.addEventListener("load", this.handleResize, false)
    // },
    // beforeDestroy: function () {
    //   window.removeEventListener('resize', this.handleResize, false)
    // },
    methods: {
      handleResize: _.debounce(function(){
        var scroll_area_height = window.innerHeight - document.getElementById('subfooter').clientHeight;
        this.$store.commit('setContentHeight', document.getElementById('main').clientHeight - scroll_area_height)
      }, 300),
      toggleMenu: function(){
        if(!this.show){
          this.show = true
        }else{
          this.show = false
        }
      },
      handleClick_changeLanguage: function(lang) {
        this.lang = lang
        this.$i18n.locale = lang
        this.$store.commit('setLang', lang)
        if(this.show){
          this.show = false
        }
      },
      // hiddenSubFooter: function(){
      //   this.$store.commit('setSubFooter', false);
      // }
    }
  }
</script>

<style lang="scss">
  @import "assets/scss/style";

  .wrapper{
    position: relative;
    z-index: 500;
  }
  
  // anime-sidebar
  .anime-sidebar {

  }
  .anime-sidebar-enter-active {
    transition: all 0.3s ease-out;
    transition-delay: 0.3s;
  }
  .anime-sidebar-leave-active {
    transition: all 0.3s ease-in;
  }
  .anime-sidebar-enter {
    transform: translateX(-40px);
    // opacity: 0;
  }
  .anime-sidebar-enter-to{
    // opacity: 1;
  }
  .anime-sidebar-leave {
    // opacity: 1;
  }
  .anime-sidebar-leave-to {
    transform: translateX(40px);
    // opacity: 0;
  }

  //  page
  .anime-enter-active {
    // transition: all 0.3s ease-out;
    transition: all 0.3s cubic-bezier(.05,.37,.38,1);
    transition-delay: 0.3s;
  }
  .anime-leave-active {
    transition: all 0.3s cubic-bezier(.05,.37,.38,1);
    // transition: all 0.3s cubic-bezier(.5,.01,1,.5);
  }
  .anime-enter{
    transform: translate3d(-50px, 0, 0);
    opacity: 0;
  }
  .anime-enter-to{
    opacity: 1;
  }
  .anime-leave {
    transform: translateX(0);
    opacity: 1;
  }
  .anime-leave-to {
    transform: translate3d(50px, 0, 0);
    opacity: 0;
  }

  .detail--nav{
    position: fixed;
    z-index: 900;
    left: 2rem;
    bottom: 5vh;
  }
</style>
