<template>
  <main id="main" class="projects">
    <section class="section__detail" v-if="hasContents" key="project-detail">
      <div class="content">
        <div class="content__inner">
          <div class="section__detail--list">
            <article class="section__detail--list--item">
              <div class="detail--block" v-inview:once="showMeta">
                <h2 class="detail--name">
                  <span class="en" v-html="res.project_title"></span>
                  <span class="jp" v-html="$t('name')"></span>
                  <div class="detail--link" v-if="res.external_link">
                  <a v-bind:href="res.external_link" target="_blank">Visit Site
                  <div class="link--arrow">
                    <img src="../assets/images/h_arrow.svg" alt="">
                  </div></a>
                </div>
                </h2>

                <div class="detail--spec">
                  <p class="discription" v-html="$t('lead')" v-if="$t('lead')"></p>
                  <div class="meta">
                    <div class="tag">
                      <ul class="tag--list">
                        <li class="tag--list--item"  v-for="(tag, index) in res.tags" :key="index">{{tag.term_name}}</li>
                      </ul>
                    </div>
                    <div class="link" v-if="res.external_link">
                      <a v-bind:href="res.external_link" target="_blank">Visit Site
                      <div class="link--arrow">
                        <img src="../assets/images/h_arrow.svg" alt="">
                      </div></a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="detail--spec--list" v-for="(item, index) in res[this.$store.state.lang]['content_block']" :key="index">
                <figure class="image" v-if="$t(item.image)" v-inview:once="showContent">
                  <template>
                    <v-lazy-image :src="$t(item.image)" :width="$t(item.width)" :height="$t(item.height)" alt="" />
                  </template>
                </figure>

                <figure class="image" v-if="$t(item.movie)" v-inview:once="showContent">
                  <video v-bind:src="$t(item.movie)" width="900" muted autoplay loop playsinline></video>
                </figure>

                <h2 class="title" v-if="$t(item.heading)" v-html="$t(item.heading)" v-inview:once="showContent"></h2>

                <div class="discription" v-if="$t(item.text)" v-inview:once="showContent">
                  <p v-html="$t(item.text)"></p>
                </div>
              </div>

              <div class="article--footer--meta">
                <div class="tag">
                  <ul class="tag--list">
                    <li class="tag--list--item"  v-for="(tag, index) in res.tags" :key="index">{{tag.term_name}}</li>
                  </ul>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
      <div id="detail--spec--nav" class="detail--spec--nav__container" v-inview:once="showNextLink">
        <router-link :to="{ path : res.next.slug}" class="detail--spec--nav--link">
          <div class="content">
            <div class="content__inner">
              <div class="detail--spec--nav">
                <div class="nav--left">
                  <div class="nav--link">Next Projects</div>
                </div>
                <div class="nav--right">
                  <div class="nav--next">
                    <div class="project--number">{{ res.next.num | zeroPadding() }}</div>
                    <div class="project--name">{{res.next.title}}</div>
                    <div class="next--arrow">
                      <img src="../assets/images/h_arrow.svg" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </section>
  </main>
</template>

<script>
  // import TweenMax from 'gsap'
  // import TimelineMax from 'gsap'
  import axios from 'axios';

  export default {
    name: 'projectdetail',
    data () {
      return {
        contents: {},
        res: {},
        api: '',
        slug: ''
      }
    },
    computed: {
      hasContents: function() {
        return Object.keys(this.contents).length;
      }
    },
    created() {
      this.api = "https://cicata.jp/api/project/" + this.$route.params.projectname + "/";
      this.slug = "https://cicata.jp/projects/" + this.$route.params.projectname + "/";
      this.getContent();
    },
    watch: {
      '$route': function (to, from) {
        this.$store.commit('setSubFooter', false);
        if (to.path !== from.path) {
          this.api = "https://cicata.jp/api/project/" + to.path + "/";
          this.slug = "https://cicata.jp/projects/" + to.path + "/";
          this.getContent();
        }
      },
    },
    methods:{
      showMeta(el) {
        el.enter = function(el){ 
          TweenMax.fromTo(el, 0.8, {opacity:0, y:50}, {opacity:1, y:0, delay: 0.3})
        }
      },
      showContent(el) {
        el.enter = function(el){ 
          TweenMax.fromTo(el, 0.8, {opacity:0, y:50}, {opacity:1, y:0, delay: 0.3})
        }
      },
      showNextLink(el) {
        el.enter = function(){
          const bgTarget = document.querySelector('.detail--spec--nav--link');
          const linkTarget = document.querySelector('.nav--link');
          linkTarget.style.opacity = 0;
          const nameTarget = document.querySelector('.nav--next');
          nameTarget.style.opacity = 0;
          
          let tl = new TimelineMax();
          tl
            .fromTo(bgTarget, 0.5, {scaleX: 0}, {scaleX: 1, delay: 0.5})
            .fromTo(linkTarget, 0.5, {opacity:0, y:20}, {opacity:1, y:0})
            .fromTo(nameTarget, 0.5, {opacity:0, y:20}, {opacity:1, y:0});
        }
      },
      getContent() {
        this.$store.commit('setLoading', true);
        this.$store.commit('setSubFooter', false);
        this.contents = {};
        
        axios.get(this.api)
          .then(response => {
            this.contents = response.data;
            let result = {}
            result['ja'] = {}
            result['en'] = {}

            Object.keys( this.contents ).forEach( function(val){
              //  meta
              if ( val.match('project_title')){
                result['project_title'] = this[val];
              }
              if ( val.match('external_link')){
                result['external_link'] = this[val];
              }
              if ( val.match('_ja')){
                var key_ja = val.replace(/_ja/, "");
                result['ja'][key_ja] = this[val];
              }
              if ( val.match('_en')){
                var key_en = val.replace(/_en/, "");
                result['en'][key_en] = this[val];
              }

              //  tags
              if ( val.match('tags') ){
                result['tags'] = this[val];
              }

              // next
              if ( val.match('next') ){
                result['next'] = this[val];
              }

              //  content block
              if ( val.match('content_block') ){
                result['ja']['content_block'] = []
                result['en']['content_block'] = []

                let count = 0;
                Object.keys( this['content_block'] ).forEach( function(v){
                  result['ja']['content_block'][count] = {};
                  result['en']['content_block'][count] = {};

                  Object.keys( this[v] ).forEach( function(vc){
                    if ( vc.match('text_ja')){
                      result['ja']['content_block'][count]['text'] = this[vc];
                    }
                    if ( vc.match('text_en')){
                      result['en']['content_block'][count]['text'] = this[vc];
                    }
                    if ( vc.match('heading_ja')){
                      result['ja']['content_block'][count]['heading'] = this[vc];
                    }
                    if ( vc.match('heading_en')){
                      result['en']['content_block'][count]['heading'] = this[vc];
                    }
                    if ( vc.match('image_url')){
                      result['ja']['content_block'][count]['image'] = this[vc];
                      result['en']['content_block'][count]['image'] = this[vc];
                    }
                    if ( vc.match('image_width')){
                      result['ja']['content_block'][count]['width'] = this[vc];
                      result['en']['content_block'][count]['width'] = this[vc];
                    }
                    if ( vc.match('image_height')){
                      result['ja']['content_block'][count]['height'] = this[vc];
                      result['en']['content_block'][count]['height'] = this[vc];
                    }
                    if ( vc.match('movie')){
                      result['ja']['content_block'][count]['movie'] = this[vc];
                      result['en']['content_block'][count]['movie'] = this[vc];
                    }
                  }, this[v]);
                  count++;
                }, this['content_block']);
              }
            }, this.contents );

            this.$i18n.setLocaleMessage('ja', result['ja']);
            this.$i18n.setLocaleMessage('en', result['en']);
            this.res = result;
            document.title = this.contents.title + " - " + this.contents.name_ja + " | CICATA, Inc.（シカタ株式会社）- ブランディングデザインファーム";
            this.$store.commit('setSidebarTitle', this.contents.title);
            this.$store.commit('setLoading', false);
            this.$store.commit('setSubFooter', true);
          }).catch( error => {
            this.$store.commit('setLoading', false);
          });
      }
    }
  }
</script>

<style scoped>
#main{
  min-height: 100vh;
}
.v-lazy-image {
  opacity: 0;
  transition: opacity 0.5s;
}
.v-lazy-image-loaded {
   opacity: 1;
}
</style>