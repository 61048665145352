import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lang: 'ja',
    scrollY: 0,
    page: '',
    contentHeight: 0,
    loading: true,
    sidebar_title: '',
    subfooter_show: false,
    isJa: true,
    isEn: false,
    projectsLoad: false,
  },
  mutations: {
    setLoading (state, payload){
      state.loading = payload
    },
    setProjectsLoad (state, payload){
      state.projectsLoad = payload
    },
    setSubFooter (state, payload){
      state.subfooter_show = payload
    },
    setPage (state, payload){
      state.page = payload
    },
    setSidebarTitle (state, payload){
      state.sidebar_title = payload
    },
    setLang (state, lang){
      state.lang = lang
      if ( lang == 'ja'){
        state.isJa = true
        state.isEn = false
      }else{
        state.isJa = false
        state.isEn = true
      }
    },
    setScrollY(state, dy){
      state.scrollY = dy
    },
    setContentHeight(state, height){
      state.contentHeight = height
    }
  },
  actions: {

  }
})
